import React from 'react'
import { pathToRegexp } from 'path-to-regexp'
import _ from 'lodash'
import TagManager from 'react-gtm-module'
import 'moment/locale/da'
import { useHistory, Link } from "react-router-dom";
//import axios from 'axios'
import { ROUTES } from '../components/navigation/route-constants'
import { APP_MODES, SITE_TYPES } from '../api/api-constants'
import API_CONSTANTS from '../api/api-constants'
import { setCardholder } from '../api/ticketgate-api'
import { getParams } from '../utilities/location-utilities'
import localStorageUtils from '../utilities/localstorage-utilities'
import { logTrace, sendLogSms } from '../api/orderflow-api'
import { languageChanged, siteTypeChanged } from './app-actions'
import { getOrganizer } from './organizer-actions'
import * as movieListActions from './movielist-actions'
import { startOrder, calculateTotalPrice } from './order-actions'
import { bookFailed } from './customer-actions'
import { getReservationInfo, receiptSuccess, activateReservation } from './receipt-actions'
import { book } from './customer-actions'
import CONSTANTS from '../components/orderflow/orderflow-constants'
import xml2js from '../lib/xml-to-js'
import moment from 'moment'


export default function initializeApp() {
    const queryParameters = getParams(window.location.href);
   // let history = useHistory();

   // console.log(queryParameters, 'queryParameters')
    let rid = queryParameters.rid || queryParameters.reservationid;
    let dd = window.location.pathname === '/book-kvittering' || queryParameters.accept

    const reservationPath = pathToRegexp(`${ROUTES.ORDERFLOW.RESERVATION}/:reservationId`).exec(window.location.pathname);
    if (reservationPath) rid = reservationPath[1];

    return (dispatch, getState) => {
        const organizer = getState().organizer;
        //TODO move this to DibsContainer if possible:
        // complete transaction if we came from dibs

        //if (queryParameters.orderid && queryParameters.transact) {
        //    const organizer = getState().organizer;
        //    checkAndLoadCustomStyles(organizer);
        //    initGoogleTracking(organizer);
        //    const customer = getState().customer;
        //    const transactionId = getState().order.selected.transactionId;
        //    setCardholder(getState().user.clubCardId || 0);

        //    const message = `transactionId: ${transactionId}, orderId: ${queryParameters.orderid}, dibs transact: ${queryParameters.transact}, orgId: ${organizer.id}, customer email: ${customer.email}, customer phone: ${customer.phone}, payType: ${queryParameters.paytype}`;
        //    logTrace('initializeApp - AFTER DIBS ACCEPT URL', message);

        //    if (transactionId) {
        //        logTrace('initializeApp - CALLING BOOK', message);
        //        return dispatch(book(transactionId, queryParameters.orderid, parseInt(queryParameters.amount)));
        //    }
        //    else {
        //        logTrace('initializeApp - NO TRANSACTIONID = NO BOOK', message, "critical");
        //        sendLogSms({ message: 'NO BOOK! ' + message });

        //        const { location } = window;
        //        const path = _.replace(location.pathname, '/dibs', ROUTES.ORDERFLOW.TICKETS);
        //        dispatch(bookFailed({ //The lack of actually calling book is the failure
        //            message: 'Der skete en fejl! Din bestilling gik ikke igennem, prøv igen.',
        //            buttons: <input type="button" className="start-over" onClick={() => location.href = `${location.origin}${path}?org=${queryParameters.org}`} value="OK" />
        //        }));
        //    }
        //} else

        // ABOVE IF STATEMENT CAN BE REMOVED BECAUSE DIBS IS NOT IN USE ANYMORE

          //  if (!queryParameters.cancel && queryParameters.invoice) { //Reepay..Can we move this to ReepayContainer?
        //    const organizer = getState().organizer;
        //    checkAndLoadCustomStyles(organizer);
        //    initGoogleTracking(organizer);
        //    const customer = getState().customer;
        //    setCardholder(getState().user.clubCardId || 0);
               // dispatch({ type: 'todos/todoAdded', payload: 'Learn about actions' })
           //const transactionId = queryParameters.transactionId;
        //    logTrace('initializeApp - AFTER REEPAY ACCEPT URL ', document.URL);
        //    const message = `transactionId: ${transactionId}, orderId: ${queryParameters.invoice}, reepay id: ${queryParameters.id}, orgId: ${organizer.id}, customer email: ${customer.email}, customer phone: ${customer.phone}`;

        //    if (transactionId) {
        //        const siteType = getState().siteType;
        //        logTrace('initializeApp - CALLING BOOK', message);
        //        return dispatch(getOrganizer(organizer.id, siteType, customer.email)).then(() => //Needed to set the timezone correctly...
        //            dispatch(book(transactionId, queryParameters.id, parseInt(queryParameters.amount), queryParameters.invoice)));          // HER ER BOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOK
        //    }
        //    else {
        //        logTrace('initializeApp - NO TRANSACTIONID = NO BOOK', message, "critical");
        //        sendLogSms({ message: 'NO BOOK! ' + message });

        //        const { location } = window;
        //        const path = _.replace(location.pathname, '/reepay', ROUTES.ORDERFLOW.TICKETS);
        //        dispatch(bookFailed({ //The lack of actually calling book is the failure
        //            message: 'Der skete en fejl! Din bestilling gik ikke igennem, prøv igen.',
        //            buttons: <input type="button" className="start-over" onClick={() => location.href = `${location.origin}${path}?org=${queryParameters.org}`} value="OK" />
        //        }));
        //    }

      //  }

      
      
        if (rid) return dispatch(initAsReservationOnly(rid, queryParameters.key, queryParameters.lang));

        else if (window.location.pathname === '/reepay-book-landing' || queryParameters.accept ||
            window.location.pathname === '/ebillet-program' || window.location.pathname === '/ebillet-film' || window.location.pathname === '/film-details') return dispatch(getTodo());
        //
        //else if (dd)
        //    return dispatch(initWh(queryParameters))

        else
            return dispatch(initWith(queryParameters));

    }
}



 const dummyData = [
    {
        "name": "DISPATCH"
      
    }
   
    ]
    

function getTodo() {

    return (dispatch) => {
              return fetch(dummyData)
           
            .then(result => {
               // console.log(result)
                return dispatch({ type: 'test', payload: result })
            })


    }
}


function initAsReservationOnly(rid, HPKey, appLocale) {
    const reservationId = checkForReservationId(rid);
    return (dispatch) => {
        return dispatch(getReservationInfo(reservationId, HPKey)).then((result) => {
            if (!result.error) {
                const receipt = result.transaction;
                const movieId = receipt.movieId;
                const showtimeId = receipt.showtimeId;
                return dispatch(getOrganizer(receipt.organizerId)).then((organizer) => {
                    if (appLocale) dispatch(languageChanged(appLocale));

                    checkAndLoadCustomStyles(organizer);
                    initGoogleTracking(organizer);

                    return dispatch(movieListActions.getMovieList(organizer.id)).then((movielist) => {
                        const movie = movielist.movies ? _.find(movielist.movies, (movie) => { return movie.id == movieId }) : null;
                        const showtime = movielist.showtimes ? _.find(movielist.showtimes, (showtime) => { return showtime.id == showtimeId }) : null;
                        if (showtime) {
                            let hybridEvent = !movie ? { id: -1, names: { da: receipt.movieName } } : null;

                            if (_.includes(window.location.href, 'act=buy')) {
                                return dispatch(activateReservation(reservationId, HPKey))
                                    .then(() => window.history.replaceState(null, null, `${ROUTES.ORDERFLOW.CUSTOMER}/${receipt.movieId}/${receipt.showtimeId}?org=${receipt.organizerId}&rid=${rid}&act=buy&key=${HPKey}`))
                                    .then(() => {
                                        if (hybridEvent) dispatch(movieListActions.selectedMovieUpdate(hybridEvent))
                                        else dispatch(movieListActions.selectedMovieChanged(movie.id));
                                        dispatch(movieListActions.selectedShowtimeChanged(showtime));
                                        return dispatch(calculateTotalPrice());
                                    });
                            } else {
                                if (hybridEvent) dispatch(movieListActions.selectedMovieUpdate(hybridEvent))
                                else dispatch(movieListActions.selectedMovieChanged(movie.id));
                                dispatch(movieListActions.selectedShowtimeChanged(showtime));
                                receipt.id = reservationId;
                                receipt.transactionId = reservationId; // needed for "isReceipt" checks" in OrderFlowContainer etc...
                                dispatch(receiptSuccess(receipt));
                                window.history.replaceState(null, null, `${ROUTES.ORDERFLOW.RESERVATION}/${rid}?act=cancel&key=${HPKey}`); //Delete reservation
                            }
                        }
                        return movielist;
                    });
                });
            } else return { error: result.error }
        });
    }
}

function initWith(queryParameters) {
    const organizerIds = queryParameters.org || queryParameters.organizer /*|| API_CONSTANTS.organizerUrls[window.location.host]*/; //hvis den er ukommenterret så fejler GetOrganizer når url står på localhost:3000
 //   console.log(organizerIds, 'init with here')
    return (dispatch, getState) => {
        const customerEmail = getState().customer.email;
        const siteType = queryParameters.sitetype;

        return  dispatch(getOrganizer(organizerIds, siteType, customerEmail)).then((organizer) => {
            if (organizer.error) return organizer;
            if (queryParameters.lang) dispatch(languageChanged(queryParameters.lang));
            else {
                const localeFromCookie  = localStorageUtils.getItem('locale');
                const availableLocales  = organizer.configuration.availableLocales;
                const localeIsAvailable = _.find(availableLocales, { id: localeFromCookie });
                if (localeFromCookie && localeIsAvailable) dispatch(languageChanged(localeFromCookie));
                else if (availableLocales.length > 0) dispatch(languageChanged(availableLocales[0]));
            }

            dispatch(siteTypeChanged(siteType));
            checkAndLoadCustomStyles(organizer, siteType, queryParameters.design);
            initGoogleTracking(organizer);
            if (queryParameters.newwin) organizer.configuration.openOrderInNewWindow = queryParameters.newwin === 'true';

            //For old drupal sites..REMOVE as soon as drupal sites are dead
            const showtimeId = queryParameters.showtime;
            if (queryParameters.organizer && showtimeId) {
                organizer.configuration.appMode = APP_MODES.ORDERFLOW; // Mutating directly because it's used to determine to show backbuttons...

                return dispatch(movieListActions.getMovieList(organizerIds)).then((movielist) => {
                    const showtime = movielist.showtimes ? _.find(movielist.showtimes, (showtime) => { return showtime.id == showtimeId }) : null;
                    document.body.className = "drupal";
                    window.history.replaceState(null, null, `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`);

                    return dispatch(movieListActions.selectedMovieChanged(showtime.movieId))
                        .then(() => dispatch(movieListActions.selectedShowtimeChanged(showtime)))
                        .then(() => dispatch(startOrder(showtime.id)));
                });
            }
            //---

            return organizer;
          }
          ) 
    }
}

function initGoogleTracking(organizer) {
    if (organizer.configuration.googleAnalytics && organizer.configuration.googleAnalytics.gtmId) {
        const tagManagerArgs = { gtmId: organizer.configuration.googleAnalytics.gtmId };
        TagManager.initialize(tagManagerArgs);
    }
}

function checkForReservationId(rid) {
    const length = rid.length;
    const checkNumber = +rid.substr(length - 1, length);
    const reservationId = +rid.substr(0, length - 1);
    let value = reservationId;
    let sum = 0;

    while (value) {
        sum += value % 10;
        value = Math.floor(value / 10);
    }

    return sum % 10 === checkNumber ? reservationId : null;
}

function checkAndLoadCustomStyles(organizer, siteType, design) {
    if (siteType == SITE_TYPES.APP) {
        const path = '/css/mobile-app.css';
        let umbracoOverrides = document.createElement('link');
        umbracoOverrides.href = path;
        umbracoOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(umbracoOverrides);
    }
    else if (siteType == SITE_TYPES.UMBRACO) {
        const path = '/css/umbraco.css';
        let umbracoOverrides = document.createElement('link');
        umbracoOverrides.href = path;
        umbracoOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(umbracoOverrides);
    }

    if (organizer.configuration && organizer.configuration.hasCustomStyles) {
        const path = '/custom-organizer-styles/organizer_' + organizer.id + '.css?version=' + moment().format('YYYYMMDDHHmmss');
        let styleOverrides = document.createElement('link');
        styleOverrides.href = path;
        styleOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(styleOverrides);
    }

    if (design) {
        const path = '/css/designs/design_' + design + '.css?version=' + moment().format('YYYYMMDDHHmmss');
        let styleOverrides = document.createElement('link');
        styleOverrides.href = path;
        styleOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(styleOverrides);
    }
}