import './giftcards.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { hasDecimals, withTwoDecimals } from '../../../utilities/validation-utilities'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import { ROUTES } from '../../navigation/route-constants'
import VoucherDisplay from '../vouchers/VoucherDisplay'
import VoucherInput from '../vouchers/VoucherInput'
import VoucherErrors from '../vouchers/VoucherErrors'
import { VOUCHER_TYPES } from '../../../api/api-constants'
import LOCALE from './GiftCardLocale'
import { appHeightChanged } from '../../../actions/app-actions'

class GiftCardContainer extends React.Component {
    constructor() {
        super();
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
    }

    navBack() {
        const { history } = this.props;
        history.goBack();
    }

    navNext() {
        const { organizerId, showtime, transactionId, customer, actions, totalPrice, history, giftCardAmount } = this.props;

        if (giftCardAmount > 0 && giftCardAmount < totalPrice && customer.creditCard || totalPrice === 0) {
            actions.preBook(transactionId, customer).then((result) => {
                if (!result.error) {
                    if (totalPrice === 0) {
                        actions.book(transactionId, 'Free', totalPrice)
                            .then((result) => { if (!result.error) history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`) });
                    } else history.push(`${ROUTES.ORDERFLOW.DIBS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
                }
            });
        } else history.push(`${ROUTES.ORDERFLOW.PAYMENT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    render() {
        const { bottomBarAllwaysVisible, appLocale, totalPrice, selected, vouchers, actions, voucherConstraints } = this.props;
        const showWithDecimals = hasDecimals(selected.totalTicketPrice) || hasDecimals(selected.totalFees) || hasDecimals(selected.totalExtraPurchasesPrice) || hasDecimals(selected.giftCardAmount) || hasDecimals(totalPrice);

        const ticketPriceAsText = showWithDecimals ? withTwoDecimals(selected.totalTicketPrice) : selected.totalTicketPrice;
        const feesAsText = showWithDecimals ? withTwoDecimals(selected.totalFees) : selected.totalFees;
        const extrasPriceAsText = showWithDecimals ? withTwoDecimals(selected.totalExtraPurchasesPrice) : selected.totalExtraPurchasesPrice;
        const totalPriceAsText = showWithDecimals ? withTwoDecimals(totalPrice) : totalPrice;
        const clubCardPoints = selected.clubCardPoints ? <p>{LOCALE[appLocale].clubCard} <span>{selected.clubCardPoints} point</span></p> : null;

        return (
            <div className="GiftCardContainer" >
                <div className="content-container">
                    <h1>{LOCALE[appLocale].title}</h1>
                    <div className="overview">
                        <p>{LOCALE[appLocale].tickets} <span>{ticketPriceAsText} {LOCALE[appLocale].currency}</span></p>

                        <VoucherDisplay type={VOUCHER_TYPES.TICKETS}
                            appLocale={appLocale}
                            tickets={selected.tickets}
                            vouchers={vouchers}
                            vouchersChanged={actions.vouchersChanged}
                        />

                        {selected.totalFees > 0 ? <p>{LOCALE[appLocale].fees} <span>{feesAsText} {LOCALE[appLocale].currency}</span></p> : null}
                        {selected.extraPurchases.length > 0 ? <p>{_.capitalize(LOCALE[appLocale].extraPurchase)} <span>{extrasPriceAsText} {LOCALE[appLocale].currency}</span></p> : null}

                        <VoucherDisplay type={VOUCHER_TYPES.GIFTCARD}
                            appLocale={appLocale}
                            vouchers={vouchers}
                            showWithDecimals={showWithDecimals}
                            vouchersChanged={actions.vouchersChanged}
                        />

                        <hr />
                        <p>{LOCALE[appLocale].totalPrice} <span>{totalPriceAsText} {LOCALE[appLocale].currency}</span></p>
                        {clubCardPoints}
                    </div>

                    <VoucherInput type={VOUCHER_TYPES.GIFTCARD}
                        appLocale={appLocale}
                        vouchers={vouchers}
                        voucherConstraints={voucherConstraints}
                        vouchersChanged={actions.vouchersChanged}
                        appHeightChanged={actions.appHeightChanged}
                        inputInfo={LOCALE[appLocale].info}
                    />

                    <VoucherErrors errors={vouchers.errors} />
                </div>

                <div className={bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons'}>
                    <input type="button" className="back gray" onClick={this.navBack} value={LOCALE[appLocale].back} />
                    <input type="button" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        bottomBarAllwaysVisible: state.organizer.configuration.bottomBarAllwaysVisible,
        bioKlubDk: state.order.bioKlubDk,
        totalPrice: state.order.totalPrice,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        appLocale: state.app.locale,
        selected: state.order.selected,
        vouchers: state.order.selected.vouchers,
        tickets: state.order.selected.tickets,
        giftCardAmount: state.order.selected.giftCardAmount,
        voucherConstraints: state.order.voucherConstraints,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardContainer)