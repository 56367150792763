import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Route } from 'react-router-dom'
import _ from 'lodash'
import { getParams } from '../../utilities/location-utilities'
import * as orderActions from '../../actions/order-actions'
import * as movieListActions from '../../actions/movielist-actions'
import { logTrace, sendLogSms } from '../../api/orderflow-api'
import TopBar from './topbar/TopBar'
import OrderContainer from './order/OrderContainer'
import CustomerContainer from './customer/CustomerContainer'
import DibsContainer from './payment/DibsContainer'
import ReepayContainer from './payment/ReepayContainer'
import ReceiptContainer from './receipt/ReceiptContainer'
import { ROUTES } from '../navigation/route-constants'
import CONSTANTS from './orderflow-constants'
import { appHeightChanged } from '../../actions/app-actions'
import Error from '../overlays/Error'
import Info from '../overlays/Info'
import ClubCardContainer from './clubcard/ClubCardContainer';
import ExtraPurchasesContainer from './extrapurchases/ExtraPurchaseContainer'
import BioKlubDkContainer from './biografklubdanmark/BioKlubDkContainer'
import GiftCardContainer from './giftcards/GiftCardContainer'
import PaymentContainer from './payment/PaymentContainer'
import Summary from './summary/SummaryContainer'
import Queue from '../overlays/Queue'
import AltaPaymentContainer from'./payment/AltaPaymentContainer'
//import ReepayLandingPage from './payment/ReepayLandingPage'


class OrderFlowContainer extends React.Component {
    timeout = 1800000; //30 minutes
    timer = null;
    
    

    componentWillUnmount() {
        clearTimeout(this.timer);
    };



    constructor(props) {
        super(props);
        this.state = { hasTimedOut: false };
        const { currentPage, match, history, receipt } = props;

        //if (!receipt.transactionId && currentPage !== ROUTES.ORDERFLOW.TICKETS) //entry points into the flow
        //    history.replace(`${ROUTES.ORDERFLOW.TICKETS}/${match.params.movieId}/${match.params.showtimeId}${history.location.search}`);

        this.handleTimeout = this.handleTimeout.bind(this);
        this.timer = setTimeout(this.handleTimeout, this.timeout);
    }

    componentDidMount() {
        const { actions, movies, movieId, showtimeId, organizerIds, receipt, backTo, iframeId } = this.props;
        //console.log(transId, 'trans id')
        if ( !receipt.transactionId ) {
            if (movies.length == 0 && movieId && showtimeId /*&& !transId*/) {
                actions.getMovieList(organizerIds)
                    .then((movielist) => {
                        const showtime = movielist.showtimes ? _.find(movielist.showtimes, (showtime) => { return showtime.id == showtimeId }) : null;
                        return actions.selectedMovieChanged(movieId, backTo).then(() => actions.selectedShowtimeChanged(showtime)).then(() => actions.startOrder(showtime.id));
                    });
            }
            //else if (querystrings.transactionId) {
            //    history.push(`${ROUTES.REEPAY_LANDING}/${movieId}/${showtimeId}?org=${organizerIds}&transactionId=${transId}&amount=${amount}&iframeid=${iframeId}${type}`)
            //}
            else actions.startOrder(showtimeId);
        }

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    //componentWillUnmount() {
    //    this.props.actions.resetOrder();
    //}

    resetTimeout() {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.handleTimeout, this.timeout);
    }

    handleTimeout() {
        clearTimeout(this.timer);
        this.setState({ hasTimedOut: true });
        //this.props.actions.resetOrder();
    }

    render() {
        const { hasTimedOut } = this.state;
        const { currentPage, organizer, movie, showtime, posterBanners, orderType, clubCardEnabled, pageHeader, appLocale, queue, reepayError, availableLocales , order} = this.props;
    //   console.log(order, 'order from orderflow')
        const info = hasTimedOut && currentPage !== ROUTES.ORDERFLOW.RECEIPT ? <Info canClose={false} text={'Du har været inaktiv for længe'} buttons={<input type="button" className="next" value="Start Forfra" onClick={() => window.location.reload()} />} /> : null;
      //  console.log(hasTimedOut)
        const search = window.location.search;
        const paramss = new URLSearchParams(search);
        const name = paramss.get('name');
      
       
        const params = '/:movieId/:showtimeId/';
      // console.log(params)
        const clubcard = clubCardEnabled && orderType != CONSTANTS.orderTypes.buyReservation && orderType != CONSTANTS.orderTypes.reserve ? <ClubCardContainer /> : null;
        const header = pageHeader ? <div className="page-header orderflow" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;

        const orderFlow = !_.isEmpty(movie) && !_.isEmpty(showtime) ?
            <div className="OrderFlowContainer" style={{ backgroundColor: currentPage == ROUTES.ORDERFLOW.RECEIPT ? 'transparent' : '' }}>
                {currentPage != ROUTES.ORDERFLOW.RECEIPT ?
                    <Fragment>
                        {header}

                        <TopBar appLocale={appLocale}
                            organizer={organizer}
                            movie={movie}
                            showtime={showtime}
                            posterBanners={posterBanners}
                            clubCardEnabled={clubCardEnabled}
                            pageHeaderEnabled={pageHeader != null}
                            showTimer={currentPage !== ROUTES.ORDERFLOW.RECEIPT}
                            handleTimeout={this.handleTimeout}
                            availableLocales={availableLocales}
                        />

                        {clubcard}
                    </Fragment> : null}

                <Switch>
                   
                    <Route path={ROUTES.ORDERFLOW.TICKETS + params} component={OrderContainer} />
                    <Route path={ROUTES.ORDERFLOW.EXTRA_PURCHASES + params} component={ExtraPurchasesContainer} />
                    <Route path={ROUTES.ORDERFLOW.CUSTOMER + params} component={CustomerContainer} />
                    <Route path={ROUTES.ORDERFLOW.BIOKLUB_DK + params} component={BioKlubDkContainer} />
                    <Route path={ROUTES.ORDERFLOW.PAYMENT + params} component={PaymentContainer} />
                    <Route path={ROUTES.ORDERFLOW.GIFTCARDS + params} component={GiftCardContainer} />
                    <Route path={ROUTES.ORDERFLOW.DIBS + params} component={DibsContainer} />
                    <Route path={ROUTES.ORDERFLOW.REEPAY + params} component={ReepayContainer} />
                    <Route path={ROUTES.ORDERFLOW.RECEIPT + params} component={ReceiptContainer} />
                    <Route path={ROUTES.ORDERFLOW.RESERVATION + '/:reservationId'} component={ReceiptContainer} />
                    <Route path={ROUTES.ORDERFLOW.ALTA_PAY_PAYMENT + params } component={AltaPaymentContainer}/>
                    {/*<Route path={ROUTES.ORDERFLOW.REEPAY_LANDING + params} component={ReepayLandingPage} />*/}
                   

                </Switch>

             
                {currentPage != ROUTES.ORDERFLOW.RECEIPT ? <Summary currentPage={currentPage} /> : null}
                <Error />
                {info}
            </div > : <Error error={reepayError} />;

        return (queue ? <Queue queue={queue} /> : orderFlow)
    }
}

const mapStateToProps = (state, ownProps) => {
   //console.log(state, 'state')
    const currentPage = '/' + ownProps.match.params.page;
 
   // const showReceiptData = state.receipt.transactionId != 0 && (currentPage === ROUTES.ORDERFLOW.RECEIPT || currentPage === ROUTES.ORDERFLOW.RESERVATION);
    const queryParams = getParams();
    /*const clubCardId = queryParams.CardNumber ? fetchClubCardId(queryParams.CardNumber) : 0;*/

    const reepayError = queryParams.invoice && !queryParams.cancel  && _.isEmpty(state.movielist.selectedMovie) && _.isEmpty(state.movielist.selectedShowtime) ?
        { message: 'Betalingen gik igennem og dine billetter er sikret, men der gik noget galt med kvitteringssiden. Du vil dog modtage en mail om kort tid, med dine billetter.' } : null;

    if (reepayError) {
        logTrace('OrderFlowContainer - NO RECEIPT PAGE AFTER REEPAY', document.URL, "critical");
    }

    return  {
        currentPage,
      //  showReceiptData,
        organizerIds: state.movielist.organizerIds || queryParams.org,
        organizer: state.organizer,
        movieId: ownProps.match.params.movieId,
        movie: state.movielist.selectedMovie || {},
        movies: state.movielist.movies || [],
        showtimeId: ownProps.match.params.showtimeId,
        showtime: state.movielist.selectedShowtime || {},
        selected: state.order.selected,
        orderType: state.order.orderType,
        posterBanners: state.organizer.configuration.posterBanners,
        receipt: state.receipt,
        clubCardEnabled: state.organizer.configuration.clubCard != null,
        pageHeader: state.organizer.configuration.pageHeaders.orderflow,
        appLocale: state.app.locale,
        backTo: state.movielist.backTo,
        iframeId: state.app.iframeId,
        //clubCardId,
        queue: state.order.queue,
        reepayError: reepayError,
        availableLocales: state.organizer.configuration.availableLocales,
        order: state.order,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { appHeightChanged }, movieListActions, orderActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFlowContainer)