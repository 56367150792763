import * as ticketgate from '../api/ticketgate-api'
import _ from 'lodash'
import { receiptReset, receiptSuccess } from './receipt-actions'
import { orderTypeChanged } from './order-actions'
import CONSTANTS from '../components/orderflow/orderflow-constants'

// RESERVE
export const RESERVE_REQUEST = 'RESERVE_REQUEST'
export const RESERVE_SUCCESS = 'RESERVE_SUCCESS'
export const RESERVE_FAILED = 'RESERVE_FAILED'

export function reserve(transactionId, customer) {
    return dispatch => {
        dispatch(receiptReset());
        dispatch({ type: RESERVE_REQUEST, requestedAt: Date.now(), });

        let ticketgateNewsletters = chainNewsletters(customer.newsletters);
        return ticketgate.reserve(transactionId, customer.phone, customer.name, customer.password, customer.email, ticketgateNewsletters, customer.smsReminder, customer.zipCode, customer.note).then(
            response => {
                dispatch({ type: RESERVE_SUCCESS, requestedAt: Date.now() });
                return dispatch(receiptSuccess(response))
            },
            error => dispatch({
                type: RESERVE_FAILED,
                failedAt: Date.now(),
                error
            }));
    };
}

function chainNewsletters(newsletters) {
    let ticketgateNewsletters = '';
    _.each(newsletters, (letter) => {
        if (letter.enabled)
            ticketgateNewsletters += `${letter.id}_${letter.checked ? 1 : 0},`;
    });
    return _.trimEnd(ticketgateNewsletters, ',');
}

// PRE BOOK
export const PRE_BOOK_REQUEST = 'PRE_BOOK_REQUEST'
export const PRE_BOOK_SUCCESS = 'PRE_BOOK_SUCCESS'
export const PRE_BOOK_FAILED = 'PRE_BOOK_FAILED'

export function preBook(transactionId, customer) {
    return (dispatch, getState) => {
        dispatch({
            type: PRE_BOOK_REQUEST,
            requestedAt: Date.now(),
        });

        let ticketgateNewsletters = chainNewsletters(customer.newsletters);
        return ticketgate.preBook(transactionId, customer.phone, customer.name, customer.password, customer.email, 'DK', ticketgateNewsletters, customer.zipCode, customer.note)
            .then(result => {
                console.log(result, 'result')
                // PreBook doesn't handle totalAmount with giftCards so we override it here
                const totalPrice = result.transaction.prices.total;
                const giftCardTotal = _.sumBy(result.transaction.giftCards, 'amount');
                result.dibs.parameters.amount = (totalPrice - giftCardTotal) * 100; //i øre
                //--

                return result.dibs ? dispatch(preBookSuccess(result.dibs)) : dispatch(preBookFailed({}));
            }, error => dispatch(preBookFailed(error)));
    };
}

const preBookSuccess = (dibs) => {
    return {
        type: PRE_BOOK_SUCCESS,
        suceededAt: Date.now(),
        dibs
    }
}

const preBookFailed = (error) => {
    return {
        type: PRE_BOOK_FAILED,
        failedAt: Date.now(),
        error
    }
}




// BOOK
export const BOOK_REQUEST = 'BOOK_REQUEST'
export const BOOK_SUCCESS = 'BOOK_SUCCESS'
export const BOOK_FAILED = 'BOOK_FAILED'

export function book(transactionId, reference, amount, invoiceId) {
    return dispatch => {
        dispatch(receiptReset());
        dispatch(bookRequest());

        return ticketgate.book(transactionId, reference, amount, invoiceId)
            .then(receipt => {
                dispatch(bookSuccess());
                dispatch(orderTypeChanged(CONSTANTS.orderTypes.buy));
                receipt.transactionId = transactionId;
                return dispatch(receiptSuccess(receipt))
            }, error => dispatch(bookFailed(error)));
    };
}

const bookRequest = () => {
    return {
        type: BOOK_REQUEST,
        requestedAt: Date.now(),
    }
}

const bookSuccess = () => {
    return {
        type: BOOK_SUCCESS,
        succeededAt: Date.now(),
    }
}

export const bookFailed = (error) => {
    return {
        type: BOOK_FAILED,
        failedAt: Date.now(),
        error
    }
}

// CHANGE HANDLERS
export const CUSTOMER_CHANGED = 'CUSTOMER_CHANGED'
export const NEWSLETTERS_CHANGED = 'NEWSLETTER_CHANGED'
export const SMS_REMINDER_CHANGED = 'SMS_REMINDER_CHANGED'
export const CREDIT_CARD_CHANGED = 'CREDIT_CARD_CHANGED'
export const CONDITIONS_ACCEPTED_CHANGED = 'CONDITIONS_ACCEPTED_CHANGED'
export const REMEMBER_ME_CHANGED = 'REMEMBER_ME_CHANGED'
export const UPDATE_CUSTOMER_LOCAL_STORAGE = 'UPDATE_CUSTOMER_LOCAL_STORAGE'

export const customerChanged = (newCustomer) => {
    return {
        type: CUSTOMER_CHANGED,
        customer: newCustomer
    }
}

export const smsReminderChanged = (newSmsReminder) => {
    return {
        type: SMS_REMINDER_CHANGED,
        smsReminder: newSmsReminder
    }
}

export const newsletterChanged = (newNewsletters) => {
    return {
        type: NEWSLETTERS_CHANGED,
        newsletters: newNewsletters
    }
}

export const creditCardChanged = (newCreditCard) => {
    return {
        type: CREDIT_CARD_CHANGED,
        creditCard: newCreditCard
    }
}

export const conditionsAcceptedChanged = (newConditionsAccepted) => {
    return {
        type: CONDITIONS_ACCEPTED_CHANGED,
        conditionsAccepted: newConditionsAccepted
    }
}

export const rememberMeChanged = (newRememberMe) => {
    return {
        type: REMEMBER_ME_CHANGED,
        rememberMe: newRememberMe
    }
}

export const updateCustomerLocalStorage = () => {
    return {
        type: UPDATE_CUSTOMER_LOCAL_STORAGE
    }
}