export default {
    da: {
        placeholderBioKlubDk: 'Indtast kuponnummer eller TAST-SELV-KODE',
        placeholderGiftCard: 'Gavekortnummer',
        placeholderDiscount: 'Rabatkode',
        submit: 'Indløs',
        error: 'Kun bogstaver og numre tilladt',
        errorInvalidType: 'Ugyldigt nummer',
        currency: 'kr.',
        remaningAmount: 'Ny saldo: ',
        toolTipOne: 'Du kan indtaste',
        toolTipStartsWith: 'som starter med',
        toolTipMulti: 'Du kan indtaste følgende typer: '
    },

    en: {
        placeholderBioKlubDk: 'Coupon number',
        placeholderGiftCard: 'Gift card number',
        placeholderDiscount: 'Discount code',
        submit: 'Redeem',
        error: 'Only letters and numbers allowed',
        errorInvalidType: 'Invalid code',
        currency: 'DKK',
        remaningAmount: 'Remaining amount: ',
        toolTipOne: 'You can only enter',
        toolTipStartsWith: 'starting with',
        toolTipMulti: 'You can only enter the following types:'
    },

    fo: {
        placeholderBioKlubDk: 'Tøppa virðisseðil nummar',
        placeholderGiftCard: 'Gávukort nummar',
        placeholderDiscount: 'Avsláttar kota',
        submit: 'Innloys',
        error: 'Einans bókstavir og tøl kunna nýtast',
        errorInvalidType: 'Ógildugt nummar',
        currency: 'kr.',
        remaningAmount: 'Nýggj saldo: ',
        toolTipOne: 'Tú kann skriva',
        toolTipStartsWith: 'sum byrjar við',
        toolTipMulti: 'Tú kann einans skriva fylgjandi sløg:'
    }

    //new: {
    //    placeholderBioKlubDk: '',
    //    placeholderGiftCard: '',
    //    placeholderDiscount: '',
    //    submit: '',
    //    error: '',
    //    errorInvalidType: '',
    //    currency: '',
    //    remaningAmount: ': ',
    //    toolTipOne: '',
    //    toolTipStartsWith: '',
    //    toolTipMulti: ':'
    //}
}