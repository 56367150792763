import React, { useEffect } from 'react'
import _ from 'lodash'
import * as validators from '../../../utilities/validation-utilities'
import LOCALE from './CustomerLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';
import { useParams } from "react-router-dom";


export default class Customer extends React.Component {
    

    constructor(props) {
        super(props);
        const customer = props.customer;
        this.state = {
            isValid: {
                name: customer.name !== '',
                phone: validators.isValidPhone(customer.phone, props.configuration.phoneMinLength),
                email: validators.isValidEmail(customer.email),
                repeatEmail: validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail,
                password: validators.isValidPassword(customer.password),
                conditionsAccepted: customer.conditionsAccepted === true,
                zipCode: customer.zipCode !== ''
            },
            name: customer.name,
            phone:  customer.phone,
            email:   customer.email,
            repeatEmail: customer.repeatEmail,
            password:   customer.password,
            smsReminder: customer.smsReminder == null ? props.configuration.smsReminder.checkedByDefault : customer.smsReminder,
            rememberMe: customer.rememberMe,
            conditionsAccepted: customer.conditionsAccepted,
            zipCode: customer.zipCode,
            note: customer.note

        };
       
        // Need this in customer store for reserve and book
        if (customer.smsReminder == null) props.actions.smsReminderChanged(props.configuration.smsReminder.checkedByDefault);
        if (customer.newsletters.length == 0 && props.configuration.newsletters.length > 0) props.actions.newsletterChanged(props.configuration.newsletters);
        //--

        this.nameChanged = this.nameChanged.bind(this);
        this.phoneChanged = this.phoneChanged.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        this.repeatEmailChanged = this.repeatEmailChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.newsletterChanged = this.newsletterChanged.bind(this);
        this.smsReminderChanged = this.smsReminderChanged.bind(this);
        this.rememberMeChanged = this.rememberMeChanged.bind(this);
        this.customerChanged = this.customerChanged.bind(this);
        this.conditionsAcceptedChanged = this.conditionsAcceptedChanged.bind(this);
        this.zipCodeChanged = this.zipCodeChanged.bind(this);
        this.noteChanged = this.noteChanged.bind(this);
    }

    componentDidMount() {
        const { clubCardId, customer, configuration } = this.props;
       // console.log(sessionStorage.getItem('code'))
        if (sessionStorage.getItem('name')) {
        this.setState({
            isValid: {
                name: sessionStorage.getItem('name') !== null,
                phone: validators.isValidPhone(sessionStorage.getItem('phone') ? sessionStorage.getItem('phone') : customer.phone, configuration.phoneMinLength),
                email: validators.isValidEmail(sessionStorage.getItem('email') ? sessionStorage.getItem('email') : customer.email),
                password: validators.isValidPassword((sessionStorage.getItem('code') !== null) ? sessionStorage.getItem('code') : customer.password)

            },


            name: sessionStorage.getItem('name')   !== null  ? sessionStorage.getItem('name') : customer.name,
            phone: sessionStorage.getItem('phone') !== null  ? sessionStorage.getItem('phone') : customer.phone,
            email: sessionStorage.getItem('email') !== null  ? sessionStorage.getItem('email') : customer.email,
            password: sessionStorage.getItem('code') !== null  ? sessionStorage.getItem('code') : customer.password
        })
        
            this.customerChanged();
         }
    }

   
    componentDidUpdate(prevProps) {
        const { clubCardId, customer, configuration } = this.props;
        if (clubCardId && prevProps.customer != customer) {
            
            this.setState({
                isValid: {
                    name: customer.name !== ''  ,
                    phone: validators.isValidPhone(customer.phone, configuration.phoneMinLength),
                    email: validators.isValidEmail(customer.email),
                    repeatEmail: validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail,
                    conditionsAccepted: customer.conditionsAccepted === true,
                    zipCode: customer.zipCode !== ''
                },
                name : customer.name,
                phone: customer.phone,
                email:  customer.email,
                repeatEmail: customer.repeatEmail,
                conditionsAccepted: customer.conditionsAccepted,
                zipCode: customer.zipCode,
                note: customer.note
            });
        }
      
    }

    customerChanged() {
        const customer = this.state;
        this.props.actions.customerChanged({
            name: customer.name || sessionStorage.getItem('name'),
            phone: customer.phone || sessionStorage.getItem('phone'),
            email: customer.email || sessionStorage.getItem('email'),
            repeatEmail: customer.repeatEmail || sessionStorage.getItem('email'),
            password: customer.password || sessionStorage.getItem('code'),
            zipCode: customer.zipCode,
            note: customer.note
        });
    }

    nameChanged(e) {
        
        const name = e.target.value ;

        const isValid = name !== '' ;
        this.setState({ name, isValid: { ...this.state.isValid, name: isValid } });
    }

    phoneChanged(e) {
        const { configuration } = this.props;
      
        let phone = e.target.value;
        phone = this.numbersOnly(phone);

        const isValid = validators.isValidPhone(phone, configuration.phoneMinLength);
        this.setState({ phone, isValid: { ...this.state.isValid, phone: isValid } });
    }

    numbersOnly(input) {
        const nonNumericRegex = /[^0-9]+/g;
        input = input.replace(nonNumericRegex, '');
        return input;
    }

    emailChanged(e) {
        const email = e.target.value;
        const isValid = validators.isValidEmail(email);
        const repeatEmail = this.state.repeatEmail;
        const repeatEmailIsValid = validators.isValidEmail(repeatEmail) && email === repeatEmail;
        this.setState({ email, isValid: { ...this.state.isValid, email: isValid, repeatEmail: repeatEmailIsValid } });
    }

    repeatEmailChanged(e) {
        const repeatEmail = e.target.value;
        const isValid = validators.isValidEmail(repeatEmail) && this.state.email === repeatEmail;
        this.setState({ repeatEmail, isValid: { ...this.state.isValid, repeatEmail: isValid } });
    }

    passwordChanged(e) {
        const password = e.target.value;
        const isValid = validators.isValidPassword(password);
        this.setState({ password, isValid: { ...this.state.isValid, password: isValid } });
    }

    newsletterChanged(e) {
        const id = e.target.id;
        const checked = e.target.checked;
        const { customer, configuration, actions } = this.props;
        const newsletters = customer.newsletters.length > 0 ? customer.newsletters : configuration.newsletters;
        const letter = _.find(newsletters, (letter) => { return letter.id == id });
        letter.checked = checked;
        actions.newsletterChanged(newsletters);
    }

    smsReminderChanged(e) {
        const checked = e.target.checked;
        this.setState({ smsReminder: checked });
        this.props.actions.smsReminderChanged(checked);
    }

    rememberMeChanged(e) {
        const checked = e.target.checked;
        this.setState({ rememberMe: checked });
        this.props.actions.rememberMeChanged(checked);
    }

    conditionsAcceptedChanged(e) {
        const checked = e.target.checked;
        const isValid = checked === true;
        this.setState({ conditionsAccepted: checked, isValid: { ...this.state.isValid, conditionsAccepted: isValid } });
        this.props.actions.conditionsAcceptedChanged(checked);
    }

    zipCodeChanged(e) {
        let zipCode = e.target.value;
        zipCode = this.numbersOnly(zipCode);
        const isValid = zipCode !== '';
        this.setState({ zipCode, isValid: { ...this.state.isValid, zipCode: isValid }  });
    }

    noteChanged(e) {
        const note = e.target.value;
        this.setState({ note });
    }

    generateNewsletters(newsletters) {
        const { customer } = this.props;
        return _.map(newsletters, (newsletter) => {
            if (newsletter.enabled) {
                const remembered = _.find(customer.newsletters, (letter) => { return newsletter.id == letter.id });
                return <label key={newsletter.id}><input type="checkbox" id={newsletter.id} checked={newsletter.checked} onChange={this.newsletterChanged} /> {newsletter.name}</label>
            }
        });
    }

    generatePasswordInput() {
       
        const codeis = sessionStorage.getItem('code')
        const { configuration, isReserve, errors, clubCardId, appLocale } = this.props;
        const { password, isValid } = this.state;

        return configuration.customerPasswordEnabled && !clubCardId ? <div className="textbox">
            <label>{LOCALE[appLocale].password}</label><input type="password" value={password} onChange={this.passwordChanged} disabled={codeis ? "disabled" : ''} onBlur={this.customerChanged} />
            <span className={isValid.password ? 'required valid icon-check' : 'required icon-required'}></span>
            <div className="helper-text">{isReserve ? LOCALE[appLocale].passwordReserveText : LOCALE[appLocale].passwordBuyText}</div>
            
            <div className="error">{!isValid.password ? errors.password : ''}</div>
         

            
        </div> : null;
    }

    generateCircusInputs() {
        const { appLocale, errors } = this.props;
        const { isValid, zipCode, note } = this.state;

        return <div>
            <div className="textbox">
                <label>{LOCALE[appLocale].zipCode}</label><input type="text" value={zipCode} onChange={this.zipCodeChanged} onBlur={this.customerChanged} />
                <span className={isValid.zipCode ? 'required valid icon-check' : 'required icon-required'}></span>
                <div className="error">{!isValid.zipCode ? errors.zipCode : ''}</div>
            </div>
            <div className="textbox">
                <label>{LOCALE[appLocale].note}</label><input type="text" value={note} onChange={this.noteChanged} onBlur={this.customerChanged} />
            </div>
        </div>
    }

    generateMuseumInputs() {
        const { appLocale, errors } = this.props;
        const { isValid, zipCode, note } = this.state;

        return <div>
            <div className="textbox">
                <label>{LOCALE[appLocale].zipCode}</label><input type="text" value={zipCode} onChange={this.zipCodeChanged} onBlur={this.customerChanged} />
                <span className={isValid.zipCode ? 'required valid icon-check' : 'required icon-required'}></span>
                <div className="error">{!isValid.zipCode ? errors.zipCode : ''}</div>
            </div>
            <div className="textbox" hidden>
                <label>{LOCALE[appLocale].note}</label><input type="text" value={note} onChange={this.noteChanged} onBlur={this.customerChanged} />
            </div>
        </div>
    }

    render() {
        const { configuration, isReserve, errors, clubCardId, appLocale, organizerType } = this.props;
        const { name, phone, email, repeatEmail, isValid, smsReminder, rememberMe, conditionsAccepted } = this.state;
       
        const phoneis = sessionStorage.getItem('phone');
        const nameis = sessionStorage.getItem('name')
        const emailis = sessionStorage.getItem('email');
      

       // console.log(nameis,phoneis, emailis)
       
        const passwordTextBox = this.generatePasswordInput();
        const newsletterCheckboxes = configuration.newsletters.length > 0 && !clubCardId ? this.generateNewsletters(configuration.newsletters) : null;
        const smsReminderCheckbox = configuration.smsReminder.enabled && isReserve ? <label id="sms"><input type="checkbox" checked={smsReminder} onChange={this.smsReminderChanged} /> {LOCALE[appLocale].smsReminder} </label> : null;
        const newsletterGdpr = newsletterCheckboxes && configuration.gdprTexts.newsletters && !clubCardId ? <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.newsletters }}></div> : null;
        const rememberMeCheckbox = !clubCardId ? <label id="remember-me"><input type="checkbox" checked={rememberMe} onChange={this.rememberMeChanged} /> {LOCALE[appLocale].rememberMe} </label> : null;

        const circusTextBoxes = organizerType == ORGANIZER_TYPES.CIRCUS ? this.generateCircusInputs() : (organizerType == ORGANIZER_TYPES.MUSEUM ? this.generateMuseumInputs() : null);

      
        return (
            <div className="Customer content-container" >
                <h1>{LOCALE[appLocale].customerInfo}</h1>
               
               
                <div className="textbox">
                    <label>{LOCALE[appLocale].name}</label>
                    <input type="text" style={{ color: nameis ? 'darkgrey' : '' }} value={name} disabled={nameis ? "disabled" : ''} onChange={this.nameChanged} onBlur={this.customerChanged} />
                    <span className={isValid.name ? 'required valid icon-check' : 'required icon-required'}></span>
                    <div className="error">{!isValid.name ? errors.name : ''}</div>
                    

                    
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].phone}</label><input type="tel" style={{ color: phoneis ? 'darkgrey' : '' }} disabled={phoneis ? "disabled" : ''} value={ phone} onChange={this.phoneChanged} onBlur={this.customerChanged} />
                    <span className={isValid.phone  ? 'required valid  icon-check' : 'required icon-required'}></span>  
                    <div className="error">{!isValid.phone ? errors.phone : ''}</div>
                  
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].email}</label><input type="email" style={{ color: emailis ? 'darkgrey' : '' }} disabled={emailis ? "disabled" : ''} value={ email} onChange={this.emailChanged} onBlur={this.customerChanged} />
                    <span className={isValid.email  ? 'required valid  icon-check' : 'required icon-required'}></span>
                        <div className="error">{!isValid.email ? errors.email : ''}</div>
                    
                </div>
                {configuration.repeatEmail ? <div className="textbox" >
                    <label>{LOCALE[appLocale].repeatEmail}</label> <input type="email" value={repeatEmail} onChange={this.repeatEmailChanged} onBlur={this.customerChanged} />
                    <span className={isValid.repeatEmail ? 'required valid icon-check' : 'required icon-required'}></span>
                    
                    <div className="error">{!isValid.repeatEmail ? errors.repeatEmail : ''}</div>
                </div> : null}
                {passwordTextBox}
                {circusTextBoxes}

                <div className="checkboxes">
                    <div>
                        {rememberMeCheckbox}
                        {smsReminderCheckbox}
                        {newsletterCheckboxes}
                    </div>
                    {newsletterGdpr}

                    {!isReserve ? <div className="conditions">
                        <label>
                            <input type="checkbox" defaultChecked={conditionsAccepted} onChange={this.conditionsAcceptedChanged} />
                            <span dangerouslySetInnerHTML={{ __html: LOCALE[appLocale].acceptConditions }}></span>
                        </label>
                        <div className="error">{!isValid.conditionsAccepted ? errors.conditionsAccepted : ''}</div>
                    </div> : null}
                </div>
            </div>
        )
    }
}