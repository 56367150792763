import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import CONSTANTS from '../orderflow-constants'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import { orderTypeChanged } from '../../../actions/order-actions'
import * as receiptActions from '../../../actions/receipt-actions'
import DeleteReservation from './DeleteReservation'
//import { useLocation } from "react-router-dom";

class ReceiptContainer extends React.Component {
    constructor(props) {
        super(props);
        const { orderType, actions } = props;
      //  orderType == (CONSTANTS.orderTypes.buy || CONSTANTS.orderTypes.buyReservation) ? actions.gaTrackPurchase() : actions.gaTrackReservation();
       
    }

    componentDidMount() {
        const { actions, orderType, customer, receipt } = this.props;

        if (!_.includes(window.location.href, 'act=cancel')) {
            switch (orderType) {
                case CONSTANTS.orderTypes.buy:
              //  case CONSTANTS.orderTypes.buyReservation:
                     actions.getReceipt(customer.phone, receipt.orderId).then(() => this.checkIframe());
                    break;
                case CONSTANTS.orderTypes.reserve:
                    actions.getReceipt(customer.phone, receipt.id).then(() => this.checkIframe());
                    break;
            }
        }
    }

    checkIframe() {
        const { iframeId, actions } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    render() {
        const { receipt, appLocale, actions } = this.props;

       
        return (
            <div className="ReceiptContainer">
               

                {_.includes(window.location.href, 'act=cancel') ?
                    <DeleteReservation
                        receipt={receipt}
                        appLocale={appLocale}
                        deleteReservation={actions.deleteReservation}
                        gaDeleteReservation={actions.gaDeleteReservation}
                    /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log(state, 'state')
    return {
        appLocale: state.app.locale,
        orderType: state.order.orderType,
        receipt: state.receipt,
        customer: state.customer,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, gaActions, { appHeightChanged }, receiptActions, { orderTypeChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptContainer)